/*******************************
         Site Overrides
*******************************/

.ui.button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.ui.button:disabled {
  opacity: 0.5 !important;
}