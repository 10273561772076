/*******************************
        User Overrides
*******************************/

.ui.dropdown {
  width: 30rem;
}

.ui.disabled.dropdown.selection {
  opacity: @disabledOpacity;
  background: @disabledColor;
}

.ui.dropdown .menu > .item{
  font-size: 1.2rem;
}

.ui.dropdown:not(.button)>.default.text {
    color: @placeholderColorInput;
}
.ui.dropdown.error > .default.text {
    color: @placeholderColorInputError;
}
.ui.selection.active.dropdown.error .menu {
    border-color: @errorColor;
}